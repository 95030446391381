import React, { memo } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Link from 'next/link';
import Image from 'next/image';

function Index({ adsDetail }: any) {
  return (
    <>
      <div className="cg-wrap-box">
        <div className="top-rated">
          <span className="top-rated-label">Top Rated</span>
        </div>
        <div className="cg_img">
          {adsDetail.ads_images.length ? (
            <div className="item-img-sld">
              <div className="owl-carousel">
                <Carousel
                  showArrows={true}
                  infiniteLoop={true}
                  swipeable={true}
                  statusFormatter={() => `247torax`}
                  dynamicHeight={true}
                  showThumbs={false}
                  autoPlay={true}
                >
                  {adsDetail.ads_images.map((images: any, index: number) => (
                    <div key={index} className="item">
                      <div className="ads-img">
                        <Image
                          quality={75}
                          src={process.env.REACT_APP_API_UPLOADS + images.image_url}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/assets/img/no-found.webp';
                          }}
                          width={100}
                          height={100}
                          alt={adsDetail.name ?? 'Call Girls and Escort Services'}
                        />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ) : null}
        </div>
        <div className="cg-cont">
          <div className="ads-left-cont">
            <Link href={`/ads-details/${adsDetail.adsNo}`}>{`${adsDetail.extra_title} ${adsDetail.title}`}</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Index);
